<template>
  <div class="blog-search">
    <div v-if="loader" class="loader">
      <img src="~@/assets/loader.gif" alt="">
      <p>Carregando posts...</p>
    </div>
    <section v-if="!loader" class="blog-header">
      <div class="container">
        <ul>
          <li>Pesquisar por:</li>
          <li>{{ searchText }}</li>
        </ul>
      </div>
    </section>
    <section v-if="!loader" class="box-search">
      <div class="container">
        <div class="search">
          <img src="~@/assets/icons/icon-search.svg" alt="">
          <input type="text" placeholder="Buscar notícias" v-model="searchText" @change="searchChange()" />
          <a @click="searchChange()" class="button-search"><img alt="Icon arrow left" src="~@/assets/icons/arrow-left.svg"></a>
        </div>
      </div>
    </section>
    <div v-if="!loader && empty" class="empty">
      <img src="~@/assets/image-empty.svg" alt="">
      <h3>Oops!</h3>
      <p>Não encontramos nenhuma postagem com base na sua busca, por favor faça uma nova busca.</p>
    </div>
    <section v-if="!loader" class="news">
      <div class="container container-news">
        <div
          class="box-news"
          v-for="item in this.postsSearch"
          :value="item.id"
          :key="item.id"
        >
          <img class="news-image" alt="" :src="item.attributes.banner_url">
          <h3>{{ item.attributes.title }}</h3>
          <p>{{ item.attributes.description }}</p>
          <router-link
            :to="{
              name: 'BlogPost',
              params: { slug: item.attributes.slug }
            }"
          >
            Ler mais
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { blogService } from '@/services/blog.service'

export default {
  name: 'BlogSearch',
  metaInfo: {
    title: 'imoGo - Blog'
  },
  data () {
    return {
      loader: false,
      empty: false,
      postsSearch: [],
      searchText: this.$route.params.search
    }
  },

  mounted () {
    this.postSearch()
  },

  methods: {
    async postSearch () {
      this.loader = true
      const params = this.searchText
      try {
        const posts = await blogService.searchPosts(params)
        this.postsSearch = posts.data.data
        this.loader = false
        if (posts.data.data.length < 1) {
          this.empty = true
        } else {
          this.empty = false
        }
      } catch (error) {
        console.log('Ocorreu um erro inesperado.')
      }
    },

    searchChange () {
      this.postSearch()
    }

  }
}
</script>

<style lang="scss" scoped>
  .blog-search {
    padding: 0 0 100px;

    .container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      align-items: flex-start;

      &.container-news {
        max-width: 1200px;
        margin-top: 62px;
        flex: 1;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }

    h2 {
      font-size: var(--size-h2);
      line-height: 48px;
      color: var(--secundary-black);
      font-weight: 700;

      span {
        display: block;
        font-weight: 400;
        line-height: 32px;
      }
    }

    .loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 300px;
      margin: 100px 0;
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 300px;
      margin: 100px 0;
      text-align: center;

      p {
        max-width: 310px;
        margin: 0 auto;
      }

      img {
        margin-bottom: 29px;
      }
    }

    .search {
      margin: 60px 0 0px;
      background: var(--white);
      border: 1px solid var(--gainsboro);
      max-width: 324px;
      width: 100%;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 0 23px;
      height: 50px;
      position: relative;

      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }

      input {
        height: 24px;
        border: none;
        background: none;
        box-shadow: none;
        font-size: var(--size-button);
        font-weight: var(--weight-light);
        color: var(--grey-dark-custom);
        width: 100%;
        max-width: 240px;
        padding-right: 30px;
      }
      .button-search {
        width: 50px;
        position: absolute;
        right: 0px;
        min-width: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          margin: 0 0 0 0px;
        }
      }
    }

    .blog-header {
      background: var(--grey-custom);
      padding: 32px 0;

      .container {
        max-width: 1200px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          font-size: var(--size-large);
          line-height: 36px;
          color: var(--secundary-black);
          font-weight: var(--weight-light);
          display: inline-block;
          vertical-align: middle;

          span {
            margin: 0 6px;
          }

          &:first-child {
            font-weight: var(--weight-bold);
            margin-right: 6px;
          }
        }
      }
    }

    .news {
      padding: 12px 0 12px;
    }

    .box-news{
      max-width: 380px;
      width: 100%;
      margin-bottom: 30px;
      margin-right: 26px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      img {
        width: 100%;
        height: auto;
        margin-bottom: 30px;
        border-radius: 30px;
        height: 254px;
        object-fit: cover;
      }

      h3 {
        margin-bottom: 16px;
        font-weight: 700;
        line-height: 29px;
        font-size: var(--size-large);
        color: var(--atomic);
      }

      p {
        font-weight: 300;
        line-height: 29px;
        font-size: var(--size-large);
        color: var(--atomic);
        margin-bottom: 32px;
      }

      a {
        text-decoration: none;
        color: var(--green-custom);
        font-weight: 700;
      }
    }

    @media (max-width: 768px) {
      margin-top: 0;

        .news {
          padding: 20px 0 0;

          .container {
            align-items: flex-start;
            flex-direction: column;

            &.container-news {
              margin-top: 32px;
              flex-direction: column;
            }
          }

          .box-news {
            margin-bottom: 32px;

            &:last-child {
              margin-bottom: 0px;
            }

            p {
              margin-bottom: 16px;
            }
          }
        }
    }
  }
</style>
